import * as React from "react";
import Layout from "../../components/Layout";
import Section from "../../components/Layout/Section";
import Banner from "../../components/Banner";
import BreadCrumbBlock from "../../components/Breadcrumb";
import { Container, Row } from "react-bootstrap";
import Title from "../../components/Layout/Title";
import OneColumnContent from "../../components/OneColumnContent";
import TwoColumnContent from "../../components/TwoColumnContent";

// markup
const BlogDetail = () => {
  return (
    <Layout>
      <Banner />
      <BreadCrumbBlock />
      <Section noSpacingTop noSpacingBottom>
        <Container>
          <Title title="Blog title" className="text-center">
            <div className="tag-wrap subtitle subtitle-sm">
              <span>Media</span>
              <span>News</span>
              <span>Published: 2021-12-09</span>
            </div>
            <p>
              Pellentesque eu commodo risus, a rutrum nisl. Praesent bibendum
              sit amet ex sit amet tincidunt. Curabitur eget vestibulum dui, et
              placerat ante.
            </p>
          </Title>
        </Container>
      </Section>
      <Section smallSpacingTop>
        <Container>
          <p className="subtitle">Subtitle</p>
          <h3>One column Text</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
        </Container>
      </Section>
    </Layout>
  );
};

export default BlogDetail;
